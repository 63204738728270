import axios from 'axios';
const URL_AUTH = process.env.VUE_APP_AUTH_URL;

function login(user) {
  user = user || {};

  return new Promise((resolve, reject) => {
    return axios.post(`${URL_AUTH}/login`, { email: user.email, password: user.password })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function changePassword(password) {
  return new Promise((resolve, reject) => {
    return axios.put(`${URL_AUTH}/change-password`, {
      password
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    return axios.post(`${URL_AUTH}/forgot-password`, {
      email
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function validateUserByCode(email, code) {
  return new Promise((resolve, reject) => {
    return axios.post(`${URL_AUTH}/code-login`, {
      email, code
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  login,
  changePassword,
  forgotPassword,
  validateUserByCode
};