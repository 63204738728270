import Vue from 'vue';
import store from '@/vuex';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask';
import money from 'v-money';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@/static/styles/_index.scss';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import utilsStorage from '@/utils/storage';
import HighchartsVue from 'highcharts-vue';

import AccessDirective from '@/directives/access-directive.js';

import { ACCESS } from '../../common/constants/global';

Vue.config.productionTip = false;

Vue.prototype.$eventBus = new Vue();

Vue.prototype.$access = ACCESS;

Vue.use(VueMask);
Vue.use(money, { precision: 2 });

Vue.use(mixpanel);

Vue.use(HighchartsVue);

Vue.directive('disabledIconFocus', {
  bind(el) {
    disableIconFocus(el);
  },
  componentUpdated(el) {
    disableIconFocus(el);
  }
});

Vue.directive('access', AccessDirective);

function disableIconFocus(el) {
  el.querySelectorAll('.v-input__icon button').forEach((x) => x.setAttribute('tabindex', -1));
}

function getUserData() {
  let user = utilsStorage.getUserOnStorage();

  if (user) {
    Sentry.setUser({
      email: user.mail,
      id: user.id,
      username: user.name
    });
  }
}

getUserData();

Sentry.init({
  Vue,
  dsn: 'https://d4ebd2a9995c468989176cdecfe71a3c@o1319164.ingest.sentry.io/4504126479597568',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'hml-cambiohoje.deeprocket.com.br', /^\//] // TODO: Change this, when the project go to production
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

mixpanel.init('c0b4fce0f9cc40059c199d04f7d35ba9', { debug: true });

sync(store, router);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
