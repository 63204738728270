import api from '../api';

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get('/users')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(user) {
  user = user || {};

  return new Promise((resolve, reject) => {
    return api.get(`/users/${user.id}`, user)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function add(user) {
  user = user || {};

  return new Promise((resolve, reject) => {
    return api.post('/users', user)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(user) {
  user = user || {};

  return new Promise((resolve, reject) => {
    return api.put(`/users/${user.id}`, user)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function inactive(user) {
  user = user || {};

  return new Promise((resolve, reject) => {
    return api.patch(`/users/${user.id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update,
  inactive,
};
