import Pagination from '../../../../common/models/Pagination';

export default class CustomerFiltersModel extends Pagination {
  constructor(obj) {
    obj = obj || {};
    super(obj);

    this.startUpdateDate = obj.startUpdateDate;
    this.endUpdateDate = obj.endUpdateDate;
    this.registerStatusList = obj.registerStatusList;
    this.searchText = obj.searchText;
  }
}
