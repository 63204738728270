<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="heading"
          width="400px"
          class="py-4"
        />
        <v-divider />
        <v-row>
          <v-col cols="6">
            <v-skeleton-loader
              type="text"
              min-width="100px"
              class="py-5"
            />
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              type="text"
              min-width="100px"
              class="py-5"
            />
          </v-col>
        </v-row>
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="12"
      >
        <h1 class="titulo py-3">{{ formTitle }}</h1>
        <v-divider />
      </v-col>
      <v-col cols="12">
        <v-card class="card-cambio pa-5">
          <v-form ref="form">
            <v-row class="py-0">
              <v-col
                class="pb-0"
                cols="12"
                md="6"
                sm="12"
              >
                <v-text-field
                  v-model="profile.description"
                  outlined
                  dense
                  label="Descrição"
                  counter="30"
                  maxlength="30"
                  :rules="[validationIsRequiredField]"
                />
              </v-col>
              <v-col
                class="pb-0"
                cols="12"
                md="6"
                sm="12"
              >
                <v-text-field
                  v-model="profile.observation"
                  outlined
                  dense
                  label="Observação"
                  counter="255"
                  maxlength="255"
                  :rules="[validationIsRequiredField]"
                />
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col
              offset-md="9"
              align-self="end"
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
                @input="handleSearchTreeview"
                key="id"
              />
            </v-col>

            <v-col
              class="pb-0"
              cols="12"
            >
              <div class="tree-view-header text-strong">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <span>Acessos</span>
                  </v-col>
                  <v-col
                    class="hidden-sm-and-down"
                    cols="12"
                    md="2"
                  >
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          role="text"
                        >
                          Exibir
                        </div>
                      </template>
                      Exibir
                    </v-tooltip>
                  </v-col>

                  <v-col
                    class="hidden-sm-and-down"
                    cols="12"
                    md="2"
                  >
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          role="text"
                        >
                          Desabilitar
                        </div>
                      </template>
                      Desabilitar
                    </v-tooltip>
                  </v-col>

                  <v-col
                    class="hidden-sm-and-down"
                    cols="12"
                    md="2"
                  >
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          role="text"
                        >
                          Ocultar
                        </div>
                      </template>
                      Ocultar
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <v-col
              class="pt-0"
              cols="12"
            >
              <v-treeview
                ref="tree"
                open-all
                open-on-click
                :open.sync="latestOpen"
                :items="accessTree"
                :search="search"
                left
                item-key="code"
                item-text="action"
                hoverable
                dense
                item-children="children"
              >
                <template #label="{ item }">
                  <span class="text-strong">{{ item.action }}</span>
                </template>
                <template #append="event">
                  <v-radio-group
                    v-model="event.item.value"
                    :row="!isMobile"
                  >
                    <v-row :class="event.leaf ? 'align-radio-button' : 'ml-1'">
                      <v-col
                        :class="{ 'pl-0': isMobile }"
                        cols="12"
                        md="4"
                      >
                        <v-radio
                          :value="accessValue.ENABLED"
                          @click.stop="handleAccessRadioGroup(event)"
                          :label="isMobile ? 'Exibir' : ''"
                        />
                      </v-col>

                      <v-col
                        :class="{ 'pl-0': isMobile }"
                        cols="12"
                        md="4"
                      >
                        <v-radio
                          :value="accessValue.DISABLED"
                          @click.stop="handleAccessRadioGroup(event)"
                          :label="isMobile ? 'Desabilitar' : ''"
                        />
                      </v-col>

                      <v-col
                        :class="{ 'pl-0': isMobile }"
                        cols="12"
                        md="4"
                      >
                        <v-radio
                          :value="accessValue.HIDDEN"
                          @click.stop="handleAccessRadioGroup(event)"
                          :label="isMobile ? 'Ocultar' : ''"
                        />
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          class="btn-tertiary float-right"
          text
          @click="cancel"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="btn-primary mx-2 float-right"
          @click="!editMode ? createProfile() : updateProfile()"
          text
        >
          Salvar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Apis
import accessApi from '../../api/access/access-api';
import accessProfileApi from '../../api/access/access-profile-api';

//Models
import AccessModel from '@/model/access/access-model';
import AccessProfileModel from '../../model/access/access-profile-model';
import GroupAccessModel from '../../model/access/group-access-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinValidationRules from '@/mixin/mixin-validation-rules';

//Constants
import { ACCESS_VALUES } from '../../../../common/constants/global';

export default {
  name: 'FormProfile',
  mixins: [mixinMessage, mixinValidationRules],
  components: {},
  data() {
    return {
      loading: true,
      editMode: false,
      search: '',
      latestOpen: [],
      access: [],
      profile: new AccessProfileModel(),
      accessValue: ACCESS_VALUES
    };
  },

  mounted() {
    this.getAccessList();
  },
  computed: {
    isMobile() {
      return window.innerWidth <= '960';
    },

    formTitle() {
      return this.editMode ? 'Editar perfil' : 'Adicionar perfil';
    },

    accessTree: {
      get() {
        return this.access.reduce((acc, item) => {
          let group = acc.find((g) => g.id === item.originId);

          if (!group) {
            group = new GroupAccessModel({
              id: item.originId,
              code: `${item.originId}-agrupador`,
              originId: item.originId,
              action: item.originDescription
            });

            acc.push(group);
          }

          if (group.value != undefined && group.value != item.value) {
            group.value = undefined;
          }

          group.children.push(new AccessModel(item));

          if (group.value == undefined) {
            let length = [...new Set(group.children.map((i) => i.value))].length;

            if (length == 1) {
              group.value = group.children[0].value;
            }
          }

          return acc;
        }, []);
      }
    }
  },
  methods: {
    getAccessList() {
      return accessApi
        .findAll()
        .then(({ data }) => {
          this.access = data.map((a) => new AccessModel(a));

          const profileId = this.$route.params.id;

          if (profileId) {
            this.editMode = true;

            this.getProfile(profileId);
          }
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          }

          this.access = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getProfile(id) {
      accessProfileApi
        .findId(id)
        .then(({ data }) => {
          data.access.forEach((access) => {
            const index = this.access.findIndex((a) => a.code === access.code);

            if (index != -1) {
              this.access[index].value = access.value;
            } else {
              this.access.push(access);
            }

          });

          this.profile = new AccessProfileModel(data);
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          }
        });
    },

    createProfile() {
      if (!this.$refs.form.validate()) {
        this.sendMessage('Preencha os campos em destaque', 'warning');

        return;
      }

      this.profile.access = this.access;

      accessProfileApi
        .add(this.profile)
        .then(({ data }) => {
          this.sendMessage(`Perfil ${data.id} criado com sucesso`, 'success');

          this.$router.push({
            name: 'ProfilesControl'
          });
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          }
        });
    },

    updateProfile() {
      if (!this.$refs.form.validate()) {
        this.sendMessage('Preencha os campos em destaque', 'warning');

        return;
      }

      this.profile.access = this.access;

      accessProfileApi
        .update(this.profile)
        .then(({ data }) => {
          this.sendMessage(`Perfil ${data.id} editado com sucesso`, 'success');

          this.$router.push({
            name: 'ProfilesControl'
          });
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          }
        });
    },

    cancel() {
      this.$router.push({
        name: 'ProfilesControl'
      });
    },

    handleSearchTreeview() {
      this.$refs.tree.updateAll(true);
    },

    handleAccessRadioGroup(event) {
      if (!event.leaf) {
        const items = this.access.filter((a) => a.originId == event.item.originId);

        items.forEach((i) => (i.value = event.item.value));

        return;
      }

      const index = this.access.findIndex((a) => a.code === event.item.code);

      this.access[index].value = event.item.value;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-treeview-node__root:has(> .v-icon) {
  background-color: var(--v-backgroundPrimary-base);
  margin-bottom: 2px;
}

::v-deep .v-treeview-node__children > div:last-child > div {
  border-radius: 0 0 4px 4px;
}

.align-radio-button {
  margin-left: -8px;
}

.tree-view-header {
  padding: 10px 15px;
  border: 1px solid var(--v-backgroundSecondary-darken1);
  border-radius: 8px 8px 0 0;
  border-bottom: none;
}

::v-deep .v-treeview {
  border: 1px solid var(--v-backgroundSecondary-darken1);
  border-radius: 0 0 8px 8px;
}

.text-strong {
  font-size: 13px;
  font-weight: 600;
}

::v-deep .v-treeview-node__label {
  max-width: 50%;
}

::v-deep .v-treeview-node__append {
  width: 50%;
}

::v-deep div.v-treeview-node.v-treeview-node--click {
  border-bottom: 1px solid #e8eef1;
  border-top: 1px solid #e8eef1;
}

::v-deep div.v-treeview-node.v-treeview-node--click:last-child,
::v-deep div.v-treeview-node.v-treeview-node--click:first-child {
  border-bottom: none;
}

@media screen and (max-width: 942px) {
  ::v-deep .v-treeview-node__root:nth-child(1) > .v-treeview-node__level {
    display: none;
  }

  .align-radio-button {
    margin-left: 16px;
  }
}
</style>
