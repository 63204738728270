<template>
  <div class="ma-5">
    <v-row
      class="pb-2"
      v-if="!loading"
      :key="panelKey"
    >
      <v-col
        cols="12"
        md="9"
      >
        <v-row class="pb-2">
          <v-col
            cols="12"
            md="12"
          >
            <v-card class="box card-cambio">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <h1>Operações</h1>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-btn
                    text
                    class="btn-secondary float-right"
                    @click="editCustomer"
                    :disabled="!operation.customer.id"
                  >
                    <i class="far fa-user" />
                    Editar Cliente
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider />
              <v-spacer class="py-2" />
              <v-radio-group
                v-model="operation.exchangeType.id"
                row
              >
                <v-radio
                  v-for="(item, i) in filteredExchangeTypes"
                  :key="i"
                  :value="item.id"
                  :label="item.description"
                />
              </v-radio-group>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-form
              ref="formOperation"
              v-model="valid"
              v-if="operation.exchangeType.id"
            >
              <div v-if="operation.exchangeType.id == 2">
                <OperationExchange v-model="operation" />
                <OperationExchangeDeliveryAddress
                  v-if="operation.deliveryType.id == 2 && !operation.exchangeBuy"
                  v-model="operation"
                />
              </div>
              <div v-else>
                <OperationRemittance v-model="operation" />
                <OperationDocuments v-model="operation" />
              </div>
            </v-form>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-btn
              class="btn-secondary mx-2 float-right"
              @click="createOpenOperation"
              text
            >
              {{ this.editMode ? 'Atualizar Rascunho' : 'Salvar Rascunho' }}
            </v-btn>
            <v-btn
              class="btn-primary mx-2 float-right"
              @click="openSaveOperationModal"
              text
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="3"
      >
        <OperationPreview v-model="operation" />
      </v-col>
    </v-row>

    <!-- Save Operation Modal -->
    <v-dialog
      persistent
      max-width="600px"
      v-model="saveOperationModal"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Taxa do Banco</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-operation"
                ref="formBankRate"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <MoneyField
                      label="Taxa do Banco"
                      prefix="R$ "
                      v-model="operation.bankRate"
                      :precision="6"
                      :rules="[validationIsRequiredFieldNumeric]"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col
            cols="12"
            md="6"
            class="text-left px-0"
          >
            <v-btn
              class="btn-secondary mx-2 float-left"
              @click="copyBankMessageToClipboard"
              text
            >
              Copiar Simulação do Banco
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-right"
          >
            <v-btn
              text
              class="btn-primary mr-2"
              @click="createOperation"
            >
              Fechar Negócio
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="saveOperationModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Components
import OperationExchange from "@/components/form-operation/OperationExchange.vue";
import OperationPreview from "@/components/form-operation/OperationPreview.vue";
import OperationExchangeDeliveryAddress from "@/components/form-operation/OperationExchangeDeliveryAddress.vue";
import OperationRemittance from "@/components/form-operation/OperationRemittance.vue";
import OperationDocuments from "@/components/form-operation/OperationDocuments.vue";
import MoneyField from "@/components/comum/MoneyField.vue";

// Apis
import apiExchangeBankType from "@/api/exchangeBank/exchange-bank-type-api";
import apiOperation from "@/api/operation/operation-api";
import apiOperationDocuments from "@/api/operation/operation-documents-api";
import apiSketch from "@/api/general/sketch-api";
import apiTrigger from "@/api/generic/trigger-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinNestedFormValidation from "@/mixin/mixin-nested-form-validation";
import mixinValidationRules from "@/mixin/mixin-validation-rules";

// Models
import OperationModel from "@/model/operation-model";
import ExchangeBankTypeModel from "@/model/exchange-bank-type-model";
import OperationPaymentAccountModel from "@/model/operation-payment-account-model";
import SketchModel from "@/model/general/sketch-model";
import TriggerModel from "@/model/generic/trigger-model";

// Tracking
import mixpanel from "mixpanel-browser";

// Utils
import operationMessagesTemplate from "@/utils/operationMessagesTemplate";

// Constants
import { TYPES } from "../../../../common/constants/generic/sketches";
import { OPERATION_TYPE } from "../../../../common/constants/generic/types";
import { SEND_EMAIL_TRIGGER_CODES } from "../../../../common/constants/generic/triggers";
import { OPERATION_WORKFLOW } from "../../../../common/workflows/operationWorkflow";

export default {
  name: "FormOperation",
  mixins: [
    mixinMessage,
    mixinNestedFormValidation,
    mixinValidationRules,
  ],
  components: {
    OperationExchange,
    OperationPreview,
    OperationExchangeDeliveryAddress,
    OperationRemittance,
    OperationDocuments,
    MoneyField,
  },
  data() {
    return {
      operation: new OperationModel(),
      exchangeTypes: [],
      saveOperationModal: false,
      loading: false,
      editMode: false,
      valid: false,
      panelKey: 0,
    };
  },
  mounted() {
    this.getExchangeTypes();

    if (this.$route.params.id) {
      this.loading = true;
      this.getOperationBySketchId(this.$route.params.id);
      this.editMode = true;
    } else if(this.$route.query.idExchangeType) {
      this.mountOperationFromQueryString();
    } else {
      this.editMode = false;
    }
  },
  computed: {
    filteredExchangeTypes() {
      let filtered = this.exchangeTypes.filter(i => i.id != 3);

      return filtered;
    },
  },
  watch: {
     "operation.exchangeBuy"() {
      this.resetOperation();
    },
    "operation.exchangeType.id"() {
      this.resetOperation();
    },
    "operation.customer"() {
      if (this.operation.bank.paymentAccount) {
        let account = this.operation.customer.paymentAccounts.find(a => a.bank == this.operation.bank.linkedBank);
        this.operation.paymentAccount = new OperationPaymentAccountModel({ paymentAccount: true, ...account });
      } else {
        this.operation.paymentAccount = new OperationPaymentAccountModel(this.operation.bank);
      }
    },
    "operation.bank"() {
      
      if (this.operation.bank.paymentAccount) {
        let account = this.operation.customer.paymentAccounts.find(a => a.bank == this.operation.bank.linkedBank);
        this.operation.paymentAccount = new OperationPaymentAccountModel({ paymentAccount: true, ...account });
      } else {
        this.operation.paymentAccount = new OperationPaymentAccountModel(this.operation.bank);
      }
    },
  },
  methods: {
    getExchangeTypes() {
      apiExchangeBankType
        .findAll()
        .then((response) => {
          this.exchangeTypes = response.data.map(t => new ExchangeBankTypeModel(t));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getOperationBySketchId(id) {
      apiSketch
        .findId(id)
        .then((response) => {
          let sketch = response.data;
          this.operation = new OperationModel(JSON.parse(sketch.content));
          this.operation.sketchId = sketch.id;
          this.$nextTick(() => { this.loading = false; });
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    createOperation() {
      mixpanel.track("click", {
        button_name: "create_operation",
      });

      if (!this.$refs.formBankRate.validate()) {
        return;
      }

      this.operation.status = OPERATION_WORKFLOW.EM_ANALISE;

      this.saveOperation();
    },

    createOpenOperation() {
      mixpanel.track("click", {
        button_name: "create_open_operation",
      });

      if (!this.isFormValid()) {
        return;
      }
      
      this.copyCustomerMessageToClipboard();

      this.saveSketch();
    },

    saveOperation() {
      let operationDocuments = this.operation.documents;
      
      this.$eventBus.$emit('show-loading', true);
      apiOperation
        .add(this.operation)
        .then((response) => {
          this.sendMessage("Operação salva com sucesso!", "success");
          this.saveDocuments(operationDocuments, response.data);
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(() => {
          this.saveOperationModal = false;
        });
    },

    saveSketch() {
      let sketch = new SketchModel({
        content: JSON.stringify(this.operation),
        typeId: TYPES.OPERATION.id,
        agentId: this.operation.agent?.id,
        customerId: this.operation.customer?.id,
        bankId: this.operation.bank?.id,
        currencyId: this.operation.currency?.id,
      });

      if (!this.operation.sketchId) {
        this.createSketch(sketch);
      } else {
        this.updateSketch(sketch);
      }
    },

    createSketch(sketch) {
      this.$eventBus.$emit('show-loading', true);
      apiSketch
        .add(sketch)
        .then(() => {
          this.sendMessage("Rascunho salvo com sucesso!", "success");

          /* Reloads the current view */
          this.$router.go(0);
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    updateSketch(sketch) {
      
      this.$eventBus.$emit('show-loading', true);
      apiSketch
        .update(this.operation.sketchId, sketch)
        .then(() => {
          this.sendMessage("Rascunho salvo com sucesso!", "success");
          this.$router
            .push({
              name: "FormOperation",
            });
          /* Reloads the current view */
          this.$router.go(0);
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    saveDocuments(operationDocuments, operation) {
      let promisseList = [];

      operationDocuments?.forEach((document) => {
        let formData = new FormData();

        formData.append("operationId", operation.id);
        formData.append("idOperationDocumentType", document.operationDocumentType?.id);
        formData.append("document", document.document);

        promisseList.push(
          apiOperationDocuments.add(formData).then((response) => { return response.data; })
        );
      });

      Promise.all(promisseList)
        .then((itens) => {
          let documentIds = itens.map(d => d.id);
          let customerOperationalDocumentIds = this.operation.customerOperationalDocuments.map(d => d.id);

          if (this.operation.exchangeType.id == OPERATION_TYPE.REMMITANCE.id) {
            this.operationFinishedTrigger(operation, documentIds, customerOperationalDocumentIds);
            this.operationIndicationAcknowledgementTrigger(operation);
          }

          if (this.operation.sketchId) {
            apiSketch.remove(this.operation.sketchId);
            this.$router
              .push({
                name: "FormOperation",
              });
          }

          /* Reloads the current view */
          this.$router.go(0);
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(()=> {
          this.$eventBus.$emit('show-loading', false);
        });

    },

    copyCustomerMessageToClipboard() {
      navigator.clipboard.writeText(operationMessagesTemplate.getCustomerMessage(this.operation));

      this.sendMessage(
        'A mensagem do cliente foi copiada em seu CTRL + C',
        "success"
      );
    },

    copyBankMessageToClipboard() {
      navigator.clipboard.writeText(operationMessagesTemplate.getBankMessage(this.operation));

      this.sendMessage(
        'A mensagem do banco foi copiada em seu CTRL + C',
        "success"
      );
    },

    isFormValid() {
      this.reassignFormInputs(this.$refs.formOperation);
      this.$refs.formOperation.validate();

      if (!this.valid) {
        let invalidElement = this.$refs.formOperation.inputs.find(i => i.valid == false);
        invalidElement.$el.scrollIntoView({ behavior: "smooth", block: "end" });

        return false;
      }

      return true;
    },

    openSaveOperationModal(){
      if (!this.isFormValid()) {
        return;
      }

      this.saveOperationModal = true;
    },

    editCustomer() {
      mixpanel.track("click", {
        button_name: "edit_customer",
      });
      this.$router
        .push({
          name: "FormEditCustomer",
          params: {
            id: this.operation.customer.id,
          },
        });
    },

    mountOperationFromQueryString() {
      this.loading = true;
      this.operation.exchangeType.id = this.$route.query.idExchangeType;
      this.operation.customer.id = this.$route.query.idCustomer;
      this.operation.amount = this.$route.query.amount;
      this.operation.exchangeContractCost = this.$route.query.contractCost;
      this.operation.deliveryCost = this.$route.query.deliveryCost;
      this.operation.exchangeBuy = this.$route.query.exchangeBuy === 'true';
      this.$nextTick(() => { this.loading = false; });
    },

    resetOperation() {
      if (this.loading) {
        return;
      }

      this.operation = new OperationModel({ exchangeBuy: this.operation.exchangeBuy, exchangeType: this.operation.exchangeType });
      this.panelKey++;
    },

    operationFinishedTrigger(operation, documentIds, customerOperationalDocumentIds) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_FECHAMENTO,
        idOperation: operation.id,
        idCustomer: operation.customer.id,
        documentIds: documentIds,
        customerOperationalDocumentIds: customerOperationalDocumentIds,
      });

      apiTrigger
        .trigger(trigger)
        .then(() => {})
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    operationIndicationAcknowledgementTrigger(operation) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_AGRADECIMENTO_INDICACAO,
        idOperation: operation.id,
      });

      apiTrigger
        .trigger(trigger)
        .then(() => {})
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>
