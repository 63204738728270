
const TYPE_ENVIRONMENT = {
    HTML: "HML",
    PROD: "PROD"
};

const ENTITY_TYPE = {
    USER: { DESCRIPTION: "USER" },
    CUSTOMER: { DESCRIPTION: "CUSTOMER" },
};

const ACCESS_VALUES = {
  ENABLED: "H",
  DISABLED: "D",
  HIDDEN: "E",
};

const ACCESS = {
  MENU: {
    DASHBOARD: "MENU_DASHBOARD",
    CLIENTES: "MENU_CLIENTES",
    FLUXO_CADASTRAL: "MENU_FLUXO_CADASTRAL",
    OPERACOES: "MENU_OPERACOES",
    RASCUNHO_OPERACOES: "MENU_RASCUNHO_OPERACOES",
    RELATORIOS_OPERACIONAIS: {
      REMESSAS_INTERNACIONAIS: "MENU_RELATORIOS_OPERACIONAIS_REMESSAS_INTERNACIONAIS",
      LUCRO_REMESSAS_INTERNACIONAIS: "MENU_RELATORIOS_OPERACIONAIS_LUCRO_REMESSAS_INTERNACIONAIS",
      CAMBIO_TURISMO: "MENU_RELATORIOS_OPERACIONAIS_CAMBIO_TURISMO",
      LUCRO_CAMBIO_TURISMO: "MENU_RELATORIOS_OPERACIONAIS_LUCRO_CAMBIO_TURISMO",
    },
    CORBAN: {
      CADASTROS: "MENU_CORBAN_CADASTROS",
      COMISSOES: "MENU_CORBAN_COMISSOES",
    },
    CADASTROS_OPERACIONAIS: {
      NATUREZAS_OPERACAO: "MENU_CADASTROS_OPERACIONAIS_NATUREZAS_OPERACAO",
      BANCOS: "MENU_CADASTROS_OPERACIONAIS_BANCOS",
      SPREADS_MOEDAS: "MENU_CADASTROS_OPERACIONAIS_SPREADS_MOEDAS",
      METODOS_PAGAMENTO: "MENU_CADASTROS_OPERACIONAIS_METODOS_PAGAMENTO",
      APIS_COTACAO: "MENU_CADASTROS_OPERACIONAIS_APIS_COTACAO",
      TEMPLATES: "MENU_CADASTROS_OPERACIONAIS_TEMPLATES",
    },
    USUARIOS: "MENU_USUARIOS",
    PERFIS: "MENU_PERFIS",
    AUDITORIA: "MENU_AUDITORIA",
    CONFIGURACOES: "MENU_CONFIGURACOES",
    EMAILS_ENVIADOS: "MENU_EMAILS_ENVIADOS",
    ALTERAR_SENHA: "MENU_ALTERAR_SENHA",
  }
};

module.exports = {
  TYPE_ENVIRONMENT,
  ENTITY_TYPE,
  ACCESS_VALUES,
  ACCESS,
}