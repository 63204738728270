<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1>Lojas / Spreads</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            cols="12"
            md="10"
          >
            <v-btn
              class="btn-primary ml-0"
              text
              @click="openAddStoreModal"
            >
              <i class="far fa-plus" />
              Adicionar
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            lg="3"
            sm="6"
            offset-lg="9"
            offset-sm="6"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="stores"
                :search="search"
                sort-by="id"
                sort-desc
                :items-per-page="10"
                :custom-filter="caseInsensitiveAccentsInsensitive"
              >
                <template #[`item.createdAt`]="{ item }">
                  {{ item.createdAt | date }}
                </template>
                <template #[`item.actions`]="{ item }">
                  <td class="buttons-column">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          class="btn-primary"
                          text
                          @click="editStoreModal(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="far fa-pencil" />
                          Editar
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          class="btn-secondary mx-2"
                          text
                          @click="duplicateStore(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="far fa-copy" />
                          Duplicar
                        </v-btn>
                      </template>
                      <span>Duplicar</span>
                    </v-tooltip>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Add Store Modal -->
    <v-dialog
      persistent
      scrollable
      eager
      width="90vw"
      v-model="addStoreModal"
      :key="key"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Loja</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-store"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <CpfOrCnpjField
                      is-p-j
                      v-model="store.cnpj"
                      autofocus
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Razão Social"
                      v-model="store.name"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Nome da Loja"
                      v-model="store.fantasyName"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <PhoneField
                      is-landline
                      v-model="store.landline"
                      label="Telefone Fixo"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <PhoneField
                      is-landline
                      v-model="store.cellphone"
                      label="Celular"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <MailField
                      label="E-mail"
                      v-model="store.mail"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <AddressComponent v-model="store" />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <BankComponent v-model="store" />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <v-autocomplete
                      label="Tipos de entrega"
                      :items="deliveryTypes"
                      item-text="description"
                      item-value="id"
                      v-model="store.deliveryType"
                      return-object
                      clearable
                      dense
                      outlined
                      v-disabled-icon-focus
                    />
                  </v-col>

                  <!-- Withdrawal -->
                  <template
                    v-if="store.deliveryType.id == 1 || store.deliveryType.id == 3
                    "
                  >
                    <v-col
                      class="py-0"
                      cols="12"
                      md="5"
                    >
                      <v-autocomplete
                        label="Tempo para retirada"
                        :items="withdrawalDates"
                        item-text="description"
                        item-value="id"
                        v-model="store.withdrawalDate"
                        return-object
                        clearable
                        dense
                        outlined
                        v-disabled-icon-focus
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-row>
                        <v-col
                          class="py-0"
                          cols="12"
                          md="2"
                        >
                          <h4 class="py-2">Dias de retirada</h4>
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md="2"
                        >
                          <v-checkbox
                            label="Dias de semana"
                            v-model="store.withdrawalAllDays"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Domingo"
                            v-model="store.withdrawalSunday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Segunda"
                            v-model="store.withdrawalMonday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Terça"
                            v-model="store.withdrawalTuesday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Quarta"
                            v-model="store.withdrawalWednesday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Quinta"
                            v-model="store.withdrawalThursday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Sexta"
                            v-model="store.withdrawalFriday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Sábado"
                            v-model="store.withdrawalSaturday"
                            dense
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="12"
                    >
                      <v-textarea
                        auto-grow
                        outlined
                        rows="2"
                        v-model="store.businessHours"
                        label="Horário de funcionamento"
                      />
                    </v-col>
                  </template>

                  <!-- Delivery -->
                  <template
                    v-if="store.deliveryType.id == 2 || store.deliveryType.id == 3
                    "
                  >
                    <v-col
                      class="py-0"
                      cols="12"
                      md="3"
                    >
                      <v-autocomplete
                        label="Horário do delivery"
                        :items="deliveryTimes"
                        item-text="description"
                        item-value="id"
                        v-model="store.deliveryTime"
                        return-object
                        clearable
                        dense
                        outlined
                        v-disabled-icon-focus
                      />
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      md="2"
                    >
                      <MoneyField
                        label="Custo do delivery"
                        prefix="R$ "
                        v-model="store.deliveryCost"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-row>
                        <v-col
                          class="py-0"
                          cols="12"
                          md="2"
                        >
                          <h4 class="py-2">Dias de delivery</h4>
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md="2"
                        >
                          <v-checkbox
                            label="Dias de semana"
                            v-model="store.deliveryAllDays"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Domingo"
                            v-model="store.deliverySunday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Segunda"
                            v-model="store.deliveryMonday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Terça"
                            v-model="store.deliveryTuesday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Quarta"
                            v-model="store.deliveryWednesday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Quinta"
                            v-model="store.deliveryThursday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Sexta"
                            v-model="store.deliveryFriday"
                            dense
                          />
                        </v-col>
                        <v-col
                          class="py-0"
                          cols="12"
                          md=""
                        >
                          <v-checkbox
                            label="Sábado"
                            v-model="store.deliverySaturday"
                            dense
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>

                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <v-textarea
                      auto-grow
                      outlined
                      rows="3"
                      v-model="store.observation"
                      label="Observações"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <Spreads v-model="store" />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              v-if="!update"
              text
              class="btn-primary mr-2"
              @click="addStore"
            >
              Salvar
            </v-btn>
            <v-btn
              v-else-if="update"
              text
              class="btn-primary mr-2"
              @click="updateStore"
            >
              Atualizar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="addStoreModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiDeliveryTime from "@/api/exchangeStore/delivery-time-api";
import apiDeliveryType from "@/api/exchangeStore/delivery-type-api";
import apiWithdrawalDate from "@/api/exchangeStore/withdrawal-date-api";
import apiExchangeStore from "@/api/exchangeStore/exchange-store-api";

// Components
import CpfOrCnpjField from "@/components/comum/CpfOrCnpjField";
import AddressComponent from "@/components/comum/AddressComponent";
import BankComponent from "@/components/comum/BankComponent";
import MoneyField from "@/components/comum/MoneyField";
import Spreads from "./Spreads.vue";
import PhoneField from "@/components/comum/PhoneField";
import MailField from "@/components/comum/MailField";

// Models
import ExchangeStoreModel from "@/model/exchange-store-model";
import DeliveryTypeModel from "@/model/delivery-type-model";
import DeliveryTimeModel from "@/model/delivery-time-model";
import WithdrawalDateModel from "@/model/withdrawal-date-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";

// Utils
import dateUtils from "../../../../common/utils/date";

export default {
  name: "ExchangeStores",
  mixins: [mixinMessage, mixinDataTableFilters],
  components: {
    CpfOrCnpjField,
    AddressComponent,
    BankComponent,
    MoneyField,
    Spreads,
    PhoneField,
    MailField,
  },
  model: {
    prop: "bankProp",
    event: "onChange",
  },
  props: {
    bankProp: {
      type: Object,
    },
  },
  data() {
    return {
      bank: this.bankProp,
      search: "",
      stores: [],
      deliveryTypes: [],
      withdrawalDates: [],
      deliveryTimes: [],
      columns: [
        { text: "Criado Em", value: "createdAt", align: "left", sortable: true, },
        { text: "Nome", value: "fantasyName", align: "left", sortable: true },
        { text: "CNPJ", value: "cnpj", align: "left", sortable: true },
        { text: "Estado", value: "state", align: "left", sortable: true },
        { text: "Cidade", value: "city", align: "left", sortable: true },
        { text: "Email", value: "mail", align: "left", sortable: true },
        { text: "Celular", value: "cellphone", align: "left", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      store: new ExchangeStoreModel(),
      addStoreModal: false,
      update: false,
      key: 0,
    };
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
  },
  watch: {
    "store.withdrawalAllDays"() {
      this.store.withdrawalMonday = !!this.store.withdrawalAllDays;
      this.store.withdrawalTuesday = !!this.store.withdrawalAllDays;
      this.store.withdrawalWednesday = !!this.store.withdrawalAllDays;
      this.store.withdrawalThursday = !!this.store.withdrawalAllDays;
      this.store.withdrawalFriday = !!this.store.withdrawalAllDays;
    },
    "store.deliveryAllDays"() {
      this.store.deliveryMonday = !!this.store.deliveryAllDays;
      this.store.deliveryTuesday = !!this.store.deliveryAllDays;
      this.store.deliveryWednesday = !!this.store.deliveryAllDays;
      this.store.deliveryThursday = !!this.store.deliveryAllDays;
      this.store.deliveryFriday = !!this.store.deliveryAllDays;
    },
  },
  mounted() {
    this.getDeliveryTypes();
    this.getDeliveryTimes();
    this.getWithdrawalDates();
    this.stores = this.bank.exchangeStores;
  },
  computed: {},
  methods: {
    duplicateStore(item) {
      this.store = new ExchangeStoreModel(item);
      this.store.name = `Cópia da loja ${this.store.id}`;
      this.store.id = undefined;
      this.update = false;
      this.addStoreModal = true;
      this.key = this.key + 1;
    },
    getDeliveryTypes() {
      if (this.deliveryTypes[0]) {
        return;
      }

      apiDeliveryType
        .findAll()
        .then((response) => {
          this.deliveryTypes = response.data.map(
            (t) => new DeliveryTypeModel(t)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    getDeliveryTimes() {
      if (this.deliveryTimes[0]) {
        return;
      }

      apiDeliveryTime
        .findAll()
        .then((response) => {
          this.deliveryTimes = response.data.map(
            (t) => new DeliveryTimeModel(t)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    getWithdrawalDates() {
      if (this.withdrawalDates[0]) {
        return;
      }

      apiWithdrawalDate
        .findAll()
        .then((response) => {
          this.withdrawalDates = response.data.map(
            (t) => new WithdrawalDateModel(t)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    openAddStoreModal() {
      this.update = false;
      this.store = new ExchangeStoreModel();
      this.addStoreModal = true;
      this.key = this.key + 1;
    },

    editStoreModal(store) {
      this.update = true;
      this.store = new ExchangeStoreModel(store);
      this.addStoreModal = true;
      this.key = this.key + 1;
    },

    addStore() {
      if (!this.bank.id) {
        this.stores.push(new ExchangeStoreModel(this.store));
        this.addStoreModal = false;
        
        return;
      }
      
      this.$eventBus.$emit('show-loading', true);
      apiExchangeStore
        .add(this.store, this.bank.id)
        .then((response) => {
          this.stores.push(new ExchangeStoreModel(response.data));
          this.addStoreModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(()=>{
          this.$eventBus.$emit('show-loading', false);
        });
    },

    updateStore() {
      this.$eventBus.$emit('show-loading', true);

      apiExchangeStore
        .update(this.store, this.bank.id)
        .then((response) => {
          let index = this.stores.findIndex((s) => s.id == this.store.id);
          this.stores.splice(index, 1, new ExchangeStoreModel(response.data));
          this.addStoreModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(()=>{
          this.$eventBus.$emit('show-loading', false);
        });
    },
  },
};
</script>

<style></style>
