import moment from 'moment';

const FILTER_TYPE = {
  TODAY: {
    START_DATE: moment().startOf('day').format(),
    END_DATE: moment().endOf('day').format()
  },
  THIS_WEEK: {
    START_DATE: moment().startOf('week').format(),
    END_DATE: moment().endOf('week').format()
  },
  THIS_MONTH: {
    START_DATE: moment().startOf('month').format(),
    END_DATE: moment().endOf('day').format()
  },
  LAST_12_MONTHS: {
    START_DATE: moment().subtract(12, 'months').startOf('month').format(),
    END_DATE: moment().endOf('day').format()
  }
};

const FILTER_ITEMS = [
  { label: 'Hoje', value: FILTER_TYPE.TODAY },
  { label: 'Esta semana', value: FILTER_TYPE.THIS_WEEK },
  { label: 'Este mês', value: FILTER_TYPE.THIS_MONTH },
  { label: 'Últimos 12 meses', value: FILTER_TYPE.LAST_12_MONTHS }
];

export { FILTER_TYPE, FILTER_ITEMS };