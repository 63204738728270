import Vue from 'vue';
import Vuex from 'vuex';

import configurations from './module/configurations';
import access from './module/access';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    configurations,
    access
  }
});
