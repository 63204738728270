import { ACCESS_VALUES } from '../../../../common/constants/global';

export default class AccessModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.code = obj.code;
    this.originId = obj.originId;
    this.originDescription = obj.originDescription;
    this.action = obj.action;
    this.value = obj.value || ACCESS_VALUES.ENABLED;
  }
}
