import Pagination from '../../../../common/models/Pagination';

export default class EmailLogFiltersModel extends Pagination {
  constructor(obj) {
    obj = obj || {};
    super(obj);

    this.searchText = obj.searchText;
  }
}
